import { useState } from 'react';

import { Button, ButtonType } from '@admin/atoms/Button';
import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { dataSourceEnvelope } from '@admin/molecules/AutoComplete/AutoComplete';
import { getMatches } from '@admin/molecules/RichEditor/Editor/helpers';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    ModalActions,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, Match, MatchDetailSectionBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';
import { useContextData } from '@common/useContextData';

import { formFields } from './helpers';

interface Props {
    formData?: MatchDetailSectionBlockPatch;
    onSubmit: (data: MatchDetailSectionBlockPatch) => void;
    action?: ModalActions;
}

export const MatchDetailSectionModal = ({ formData, onSubmit, action = ModalActions.create }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK);
    const contextData = useContextData();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);
    const mapMatchesToSourceEnvelope = (match: Match) => ({
        text: match.id,
        element: (
            <TeamVsTeam
                kickOff={match.kickOff}
                awayTeamName={match.away?.name}
                homeTeamName={match.home?.name}
            />
        ),
    });

    const dataSource = async (searchText: string): Promise<dataSourceEnvelope[]> =>
        getMatches(contextData, searchText).then((matches = []) => matches.map(mapMatchesToSourceEnvelope));

    const handleSubmit = async (data: MatchDetailSectionBlockPatch) => {
        if (!formData?.matchID && !data.matchID) {
            setIsSubmitting(false);
            flashes.customError('insertMatchDetailSection.missingMatchID');
            return;
        }

        const nodeData: MatchDetailSectionBlockPatch = {
            matchID: data.matchID || formData?.matchID || '',
            showOdds: data.showOdds || false,
            type: ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK,
        };

        onSubmit(nodeData);
        setIsSubmitting(false);
        closeModal();
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <ThemeOverride themeID={ThemeID.ADMIN}>
            <BaseModal contextKey={ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK}>
                <BaseModalHeader contextKey={ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK}>
                    {__translate('insertMatchDetailSection.title')}
                </BaseModalHeader>
                <Form<MatchDetailSectionBlockPatch, MatchDetailSectionBlockPatch>
                    id={'match-detail-section'}
                    action={`match-detail-section-${action}`}
                    handleSubmit={handleSubmit}
                    bindDoSubmit={bindDoSubmit}
                    formFields={formFields(dataSource)}
                    entity={formData}
                />
                <BaseModalFooter className={'flex justify-between'}>
                    <Button
                        className={'w-full'}
                        filled={true}
                        loading={isSubmitting}
                        type={ButtonType.button}
                        onClick={clickButton}
                    >
                        {__translate(`insertMatchDetailSection.${action}`)}
                    </Button>
                </BaseModalFooter>
            </BaseModal>
        </ThemeOverride>
    );
};
