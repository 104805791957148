import React, { CSSProperties, useContext, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalCommand } from 'lexical';

import { Dropdown, DropdownContext, InlineButton } from '@admin/molecules/RichEditor/Editor/atoms';
import {
    EmbedIframeModal,
    INSERT_IFRAME_COMMAND,
} from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin';
import {
    Button,
    ButtonWithModal,
    InsertIframe,
    InsertInstagramPost,
    InsertJobsOverview,
    InsertMatchBlock,
    InsertMatchDetailSection,
    InsertPost,
    InsertYoutubeVideo,
} from '@admin/molecules/RichEditor/Editor/plugins/InlineToolbarPlugin/atoms';
import { EmbedInstagramModal } from '@admin/molecules/RichEditor/Editor/plugins/InstagramPlugin';
import { INSERT_JOBS_OVERVIEW_COMMAND } from '@admin/molecules/RichEditor/Editor/plugins/JobsOverviewPlugin';
import {
    INSERT_MATCH_BLOCK_COMMAND,
    MatchBlockModal,
} from '@admin/molecules/RichEditor/Editor/plugins/MatchBlockPlugin';
import {
    INSERT_MATCH_DETAIL_SECTION_COMMAND,
    MatchDetailSectionModal,
} from '@admin/molecules/RichEditor/Editor/plugins/MatchDetailSectionPlugin';
import { EmbedPostModal } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin';
import { EmbedYoutubeModal } from '@admin/molecules/RichEditor/Editor/plugins/YoutubePlugin';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockPatch, ArticleBlockType, IframeBlockPatch, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import styles from './InlineToolbarPlugin.module.scss';

enum ToolbarOptions {
    insertPost = 'insertPost',
    insertYoutubeVideo = 'insertYoutubeVideo',
    insertIframe = 'insertIframe',
    insertMatchDetailSection = 'insertMatchDetailSection',
    insertMatchBlock = 'insertMatchBlock',
    insertJobsOverview = 'insertJobsOverview',
    insertInstagramPost = 'insertInstagramPost',
}

export const InlineToolbarPlugin = () => {
    const toolbarRef = useRef(null);
    const { isOpen, close, toggle } = useContext(DropdownContext);
    const { platform, themeID } = useContextData();
    const [editor] = useLexicalComposerContext();

    const insertNode = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        command: LexicalCommand<ArticleBlockPatch>,
        data: ArticleBlockPatch,
    ) => {
        e?.preventDefault();
        editor.dispatchCommand(command, data);
        close();
    };

    const toolbarOptions = {
        [ToolbarOptions.insertPost]: (
            <div className={styles.item}>
                <ButtonWithModal modal={ArticleBlockType.X_POST_BLOCK}>
                    <InsertPost />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertYoutubeVideo]: (
            <div className={styles.item}>
                <ButtonWithModal modal={ArticleBlockType.YOUTUBE_BLOCK}>
                    <InsertYoutubeVideo />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertIframe]: (
            <div className={styles.item}>
                <ButtonWithModal modal={ArticleBlockType.IFRAME_BLOCK}>
                    <InsertIframe />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertInstagramPost]: (
            <div className={styles.item}>
                <ButtonWithModal modal={ArticleBlockType.INSTAGRAM_BLOCK}>
                    <InsertInstagramPost />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertMatchDetailSection]: (
            <div className={styles.item}>
                <ButtonWithModal modal={ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK}>
                    <InsertMatchDetailSection />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertMatchBlock]: (
            <div className={styles.item}>
                <ButtonWithModal modal={ArticleBlockType.MATCH_LIST_BLOCK}>
                    <InsertMatchBlock />
                </ButtonWithModal>
            </div>
        ),
        [ToolbarOptions.insertJobsOverview]: (
            <div className={styles.item}>
                <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        insertNode(e, INSERT_JOBS_OVERVIEW_COMMAND, {
                            type: ArticleBlockType.JOBS_OVERVIEW_BLOCK,
                        })
                    }
                >
                    <InsertJobsOverview />
                </Button>
            </div>
        ),
    };

    const platformToolbarOptions = [PlatformID.BR, PlatformID.GP].includes(platform.id)
        ? Object.values(ToolbarOptions).filter((option) => option !== ToolbarOptions.insertMatchDetailSection)
        : Object.values(ToolbarOptions);

    const style = { '--dropdownItems': platformToolbarOptions.length } as CSSProperties;

    return (
        <BaseModalProvider>
            <div className={styles.InlineToolbarPlugin} ref={toolbarRef}>
                <ThemeOverride themeID={themeID}>
                    <InlineButton onClick={toggle} className={isOpen ? styles.opened : styles.closed}>
                        <svg
                            data-testid="inline-toolbar-plugin"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16 9.6H9.6V16H6.4V9.6H0V6.4H6.4V0H9.6V6.4H16V9.6Z"
                                fill="currentColor"
                            />
                        </svg>
                    </InlineButton>
                </ThemeOverride>
                <div className={styles.position} style={style}>
                    <Dropdown className={styles.dropDown}>
                        <div className={styles.container}>
                            {platformToolbarOptions.map((option, index) => (
                                <div key={index}>{toolbarOptions[option]}</div>
                            ))}
                        </div>
                    </Dropdown>
                </div>
            </div>
            <MatchDetailSectionModal
                onSubmit={(data) => insertNode(null, INSERT_MATCH_DETAIL_SECTION_COMMAND, data)}
            />
            <EmbedPostModal />
            <EmbedInstagramModal />
            <EmbedYoutubeModal />
            <MatchBlockModal onSubmit={(data) => insertNode(null, INSERT_MATCH_BLOCK_COMMAND, data)} />
            <EmbedIframeModal
                onSubmit={(data) => {
                    editor.dispatchCommand(INSERT_IFRAME_COMMAND as LexicalCommand<IframeBlockPatch>, data);
                }}
            />
        </BaseModalProvider>
    );
};
