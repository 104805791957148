import { LexicalEditor } from 'lexical';

import { SerializedCustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode/CustomNode';
import { IframeNode } from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin';
import { JobsOverviewNode } from '@admin/molecules/RichEditor/Editor/plugins/JobsOverviewPlugin';
import { MatchBlockNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchBlockPlugin';
import { PlaceholderNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchBlockPlugin/PlaceholderNode';
import { MatchDetailSectionNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchDetailSectionPlugin';
import { PostNode } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin';
import { YoutubeNode } from '@admin/molecules/RichEditor/Editor/plugins/YoutubePlugin';
import {
    ArticleBlockPatch,
    ArticleBlockType,
    IframeBlockPatch,
    JobsOverviewBlockPatch,
    MatchDetailSectionBlockPatch,
    MatchListBlockPatch,
    RichTextBlockPatch,
    XPostBlockPatch,
    YoutubeBlockPatch,
} from '@common/clients/api';

const PARAGRAPH_TYPE = 'paragraph';

const mergeAllRichTextBlocks = (blocks: ArticleBlockPatch[]): ArticleBlockPatch[] => {
    const mergedBlocks: ArticleBlockPatch[] = [];
    let mergedText = '';

    for (const block of blocks) {
        if (block.type === ArticleBlockType.RICH_TEXT_BLOCK) {
            mergedText += block.text;
        } else {
            if (mergedText) {
                mergedBlocks.push({
                    type: ArticleBlockType.RICH_TEXT_BLOCK,
                    text: mergedText,
                } as RichTextBlockPatch);
                mergedText = '';
            }
            mergedBlocks.push(block);
        }
    }

    if (mergedText) {
        mergedBlocks.push({
            type: ArticleBlockType.RICH_TEXT_BLOCK,
            text: mergedText,
        } as RichTextBlockPatch);
    }

    return mergedBlocks;
};

export const exportEditorStateToArticleBlocks = (
    editor: LexicalEditor,
    html: string,
): Array<ArticleBlockPatch> => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(html, 'text/html');

    const blocks = editor
        .toJSON()
        .editorState.root.children.map((child, index): ArticleBlockPatch => {
            switch (child.type) {
                case PARAGRAPH_TYPE: {
                    const element = htmlDoc.body.children[index];
                    return {
                        type: ArticleBlockType.RICH_TEXT_BLOCK,
                        text: element.outerHTML,
                    } satisfies RichTextBlockPatch;
                }
                case ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK:
                    return MatchDetailSectionNode.SerializedNodeToJSON(
                        child as SerializedCustomNode<MatchDetailSectionBlockPatch>,
                    );
                case ArticleBlockType.MATCH_LIST_BLOCK:
                    return MatchBlockNode.SerializedNodeToJSON(
                        child as SerializedCustomNode<MatchListBlockPatch>,
                    );
                case ArticleBlockType.IFRAME_BLOCK:
                    return IframeNode.SerializedNodeToJSON(child as SerializedCustomNode<IframeBlockPatch>);
                case ArticleBlockType.JOBS_OVERVIEW_BLOCK:
                    return JobsOverviewNode.SerializedNodeToJSON(
                        child as SerializedCustomNode<JobsOverviewBlockPatch>,
                    );
                case ArticleBlockType.X_POST_BLOCK:
                    return PostNode.SerializedNodeToJSON(child as SerializedCustomNode<XPostBlockPatch>);
                case ArticleBlockType.YOUTUBE_BLOCK:
                    return YoutubeNode.SerializedNodeToJSON(child as SerializedCustomNode<YoutubeBlockPatch>);
                default:
                    return PlaceholderNode.SerializedNodeToJSON(
                        child as SerializedCustomNode<ArticleBlockPatch>,
                    );
            }
        })
        .filter((block): block is ArticleBlockPatch => block !== undefined);

    return mergeAllRichTextBlocks(blocks);
}; // fix as type
