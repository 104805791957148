export const urlFromIframe = (iframe: string) => {
    const srcMatch = iframe.match(/src=["']([^"']+)/);
    if (srcMatch) {
        return srcMatch[1];
    }

    const urlMatch = iframe.match(/http[^"']+/);
    if (urlMatch) {
        return urlMatch[0];
    }

    return '';
};
