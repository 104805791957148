import { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalCommand } from 'lexical';

import { Button, ButtonType } from '@admin/atoms/Button';
import { BaseModal, BaseModalFooter, BaseModalHeader, useModalContext } from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { Logo } from '@common/atoms/Logo';
import { ArticleBlockType, YoutubeBlockPatch } from '@common/clients/api';
import { sanitizeHtml } from '@web/utils/sanitizeHtml';

import { urlFromIframe } from '../../helpers/urlFromIframe';
import { formFields } from './helpers';
import { INSERT_YOUTUBE_COMMAND } from '.';

import styles from './EmbedYoutubeModal.module.scss';

interface FormEntity {
    youtubeVideo?: string;
}

interface Props {
    formData?: FormEntity;
}

export const EmbedYoutubeModal = ({ formData }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(ArticleBlockType.YOUTUBE_BLOCK);
    const [editor] = useLexicalComposerContext();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);

    const handleSubmit = async (data: FormEntity) => {
        const url = urlFromIframe(data.youtubeVideo || '');
        const isPortrait = url.includes('youtube.com/shorts');
        const videoID = url.split('/').pop()?.split('?')[0];

        if (!videoID) {
            setIsSubmitting(false);
            flashes.customError('insertYoutubeVideo.missingYoutubeUrl');
            return;
        }

        editor.dispatchCommand(INSERT_YOUTUBE_COMMAND as LexicalCommand<YoutubeBlockPatch>, {
            videoID: videoID,
            isPortrait: isPortrait,
            type: ArticleBlockType.YOUTUBE_BLOCK,
            embedBlock: sanitizeHtml(data.youtubeVideo || ''),
        });

        setIsSubmitting(false);
        closeModal();
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <BaseModal className={styles.EmbedYoutubeModal} contextKey={ArticleBlockType.YOUTUBE_BLOCK}>
            <BaseModalHeader contextKey={ArticleBlockType.YOUTUBE_BLOCK}>
                <div className={styles.title}>
                    <Logo.youtube svgProps={{ width: 28, height: 28, viewBox: '0 0 28 28' }} />
                    {__translate('insertYoutubeVideo.title')}
                </div>
            </BaseModalHeader>
            <p>
                <b>{__translate('insertYoutubeVideo.label')}</b>
            </p>
            <Form<FormEntity, FormEntity>
                id={'insertYoutubeVideo'}
                action={'insertYoutubeVideo'}
                handleSubmit={handleSubmit}
                bindDoSubmit={bindDoSubmit}
                formFields={formFields}
                entity={formData}
            />
            <BaseModalFooter>
                <Button filled={true} loading={isSubmitting} type={ButtonType.submit} onClick={clickButton}>
                    {__translate('insertYoutubeVideo.embed')}
                </Button>
            </BaseModalFooter>
        </BaseModal>
    );
};
