import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';

interface Props {
    onDelete: () => void;
    type: ArticleBlockType;
}

export const PlaceholderArticleBlock = ({ onDelete, type }: Props) => {
    const { t } = useTranslation(TranslationKey.richEditor);

    return (
        <ThemeOverride themeID={ThemeID.ADMIN}>
            <ArticleBlock onDelete={onDelete} articleBlockType={type}>
                <p>{t(`placeholderArticleBlock.${type}`)}</p>
            </ArticleBlock>
        </ThemeOverride>
    );
};
