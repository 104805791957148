import { useEffect } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $getRoot, LexicalEditor, LexicalNode } from 'lexical';

import { ArticleBlockPatch, ArticleBlockType } from '@common/clients/api';

import { MatchBlockNode } from '../MatchBlockPlugin';
import { PlaceholderNode } from '../MatchBlockPlugin/PlaceholderNode';

interface Props {
    value?: ArticleBlockPatch[];
}

export const DefaultValueArticleBlockPlugin = ({ value: defaultValue = [] }: Props) => {
    const [editor] = useLexicalComposerContext();

    const updateContent = (_innerEditor: LexicalEditor, value: ArticleBlockPatch[]) => {
        const root = $getRoot();
        const endParagraph = $createParagraphNode();

        const nodes = value
            .flatMap((block: ArticleBlockPatch): LexicalNode | LexicalNode[] | undefined => {
                switch (block.type) {
                    case ArticleBlockType.RICH_TEXT_BLOCK:
                        const parser = new DOMParser();
                        const dom = parser.parseFromString(block.text, 'text/html');
                        const textNodes = $generateNodesFromDOM(_innerEditor, dom);

                        return textNodes;
                    case ArticleBlockType.MATCH_LIST_BLOCK:
                        return MatchBlockNode.importJSON(MatchBlockNode.JSONtoSerializedNode(block));
                    default:
                        return PlaceholderNode.importJSON(PlaceholderNode.JSONtoSerializedNode(block));
                }
            })
            .filter((v): v is LexicalNode => v !== null);

        root.clear();
        root.append(...nodes, endParagraph);
    };

    useEffect(() => {
        if (editor && defaultValue) {
            editor.update(() => {
                updateContent(editor, defaultValue);
            });
        }
    }, [editor, defaultValue]);

    return null;
};
