import styles from './YoutubeVideo.module.scss';

export const YoutubeVideo = ({ videoID, isPortrait }: { videoID: string; isPortrait: boolean }) => {
    const embedVideoUrl = `https://www.youtube.com/embed/${videoID}`;

    return (
        <iframe
            data-testid="youtube-video"
            className={isPortrait ? styles.YoutubeShort : styles.YoutubeVideo}
            src={embedVideoUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
    );
};
