import { useState } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { EditorState, LexicalEditor } from 'lexical';

import { DefaultValueArticleBlockPlugin } from '@admin/molecules/RichEditor/Editor/plugins';
import { FieldType } from '@admin/types/FieldType';
import { ArticleBlock, ArticleBlockPatch } from '@common/clients/api';

import { HiddenInput } from './Editor/atoms';
import { exportEditorStateToArticleBlocks } from './Editor/plugins/ToolbarPlugin/atoms/Export/helpers';
import { Editor } from './Editor';

import styles from './RichEditor.module.scss';

interface Props {
    id: string;
    name: string;
    type: FieldType.richEditor;
    onChange: (value: ArticleBlock[]) => void;
    title?: string;
    placeholder?: string;
    value?: ArticleBlockPatch[];
}

export const ArticleBlockRichEditor = ({ id, name, onChange, value: defaultValue, ...rest }: Props) => {
    const [value, setValue] = useState<string>();

    const handleOnChange = (editorState: EditorState, editor: LexicalEditor, _tags: Set<string>) => {
        editorState.read(() => {
            const html = $generateHtmlFromNodes(editor, null);
            const blocks = exportEditorStateToArticleBlocks(editor, html);

            onChange(blocks as unknown as ArticleBlock[]);
            setValue(JSON.stringify(blocks));
        });
    };

    return (
        <div className={styles.RichEditor} data-testid={`rich-editor-${name}`}>
            <Editor {...rest} onChange={handleOnChange}>
                <DefaultValueArticleBlockPlugin value={defaultValue} />
            </Editor>
            <HiddenInput id={id} name={name} value={value} />
        </div>
    );
};
