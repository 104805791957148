import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { Logo } from '@common/atoms/Logo';

export const InsertInstagramPost = () => {
    const { t } = useTranslation(TranslationKey.richEditor);

    return (
        <>
            <Logo.instagram
                svgProps={{
                    width: 32,
                    height: 32,
                    viewBox: '0 0 32 32',
                }}
            />
            <span>{t('insertInstagramPost.button')}</span>
        </>
    );
};
