import { useState } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { EditorState, LexicalEditor } from 'lexical';

import { DefaultValuePlugin } from '@admin/molecules/RichEditor/Editor/plugins';
import { FieldType } from '@admin/types/FieldType';

import { HiddenInput } from './Editor/atoms';
import { Editor } from './Editor';

import styles from './RichEditor.module.scss';

interface Props {
    id: string;
    name: string;
    type: FieldType.richEditor;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
    title?: string;
    placeholder?: string;
    value?: string;
}

export const RichEditor = ({ id, name, onChange, onBlur, value: defaultValue, ...rest }: Props) => {
    const [value, setValue] = useState<string>();

    const handleOnChange = (editorState: EditorState, editor: LexicalEditor, _tags: Set<string>) => {
        editorState.read(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            setValue(htmlString);

            onChange(htmlString);
        });
    };

    return (
        <div className={styles.RichEditor} data-testid={`rich-editor-${name}`}>
            <Editor {...rest} onChange={handleOnChange}>
                <DefaultValuePlugin value={defaultValue} />
            </Editor>

            <HiddenInput id={id} name={name} value={value} />
        </div>
    );
};
