import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
    type DOMConversionMap,
    DOMConversionOutput,
    type DOMExportOutput,
    type ElementFormatType,
    LexicalEditor,
    NodeKey,
    type Spread,
} from 'lexical';

import { ArticleBlockType } from '@common/clients/api';

import { isDomNodeWithType } from './helpers';

export type SerializedCustomNode<T> = Spread<
    {
        data: T;
        version: 1;
    },
    SerializedDecoratorBlockNode
>;

export function CustomNode<T, U>(createNode: (data: T) => U) {
    type UCustomNode = U & DecoratorBlockNode;

    const convertElement = (domNode: HTMLDivElement): DOMConversionOutput | null => {
        const data = domNode.getAttribute(LEXICAL_DATA_ATTR);

        if (!data) {
            return null;
        }

        const node = createNode(JSON.parse(data)) as UCustomNode;
        return { node };
    };

    class CustomNode extends DecoratorBlockNode {
        static __createNode = createNode;
        static __type: ArticleBlockType;
        readonly __data: T;

        constructor(data: T, format?: ElementFormatType, key?: NodeKey) {
            super(format, key);

            this.__data = data;
        }

        getData(): T {
            return this.__data;
        }

        static override getType(): string {
            return this.__type;
        }

        override exportJSON(): SerializedCustomNode<T> {
            return {
                ...super.exportJSON(),
                data: this.getData(),
                type: this.__type,
                version: 1,
            };
        }

        override exportDOM(): DOMExportOutput {
            const element = document.createElement('div');
            element.setAttribute(LEXICAL_DATA_ATTR, JSON.stringify(this.__data));
            return { element };
        }

        static override importJSON(serializedNode: SerializedCustomNode<T>) {
            const node = CustomNode.__createNode(serializedNode.data) as UCustomNode;
            node.setFormat(serializedNode.format);
            return node;
        }

        static override importDOM(): DOMConversionMap<HTMLDivElement> | null {
            return {
                div: (domNode: HTMLDivElement) => {
                    if (!isDomNodeWithType(domNode, this.__type)) {
                        return null;
                    }

                    return {
                        conversion: convertElement,
                        priority: 2,
                    };
                },
            };
        }

        static SerializedNodeToJSON(node: SerializedCustomNode<T>) {
            return node.data;
        }

        handleOnDelete(editor: LexicalEditor) {
            return () => editor.update(() => this.remove());
        }
    }

    return CustomNode;
}
export const LEXICAL_DATA_ATTR = 'data-lexical-data';
